<template>
  <v-layout row wrap class="max-width-1400 full-width ma-auto">
    <v-flex xs12 py-5 text-xs-center>
      <p class="graphik-medium font-28 secondary--text mb-5">{{ $ml.get('content_coverages_house_title') }}</p>

      <v-layout row wrap>
        <v-flex xs12 sm6 lg3 v-for="(step, index) in coverages_house" :key="index" class="px-5">
          <v-img
            :aspect-ratio="1"
            contain
            class="ma-auto mb-2"
            position="center bottom"
            :src="step.icon"
            :lazy-src="step.icon"
            width="45px"
            max-height="45px"
          ></v-img>

          <p class="graphik-medium font-20 dark-grey--text">{{ step.title }}</p>

          <p class="graphik-light font-15 dark-grey--text mb-4 max-width-250 mx-auto">{{ step.description }}</p>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentHowItWorks',
  data () {
    return {
      coverages_house: [
        {
          title: this.$ml.get('content_coverages_house_step_1'),
          description: this.$ml.get('content_coverages_house_step_1_description'),
          icon: require('@/assets/img/icons/blue/new_house.png')
        },
        {
          title: this.$ml.get('content_coverages_house_step_2'),
          description: this.$ml.get('content_coverages_house_step_2_description'),
          icon: require('@/assets/img/icons/blue/new_room.png')
        },
        {
          title: this.$ml.get('content_coverages_house_step_3'),
          description: this.$ml.get('content_coverages_house_step_3_description'),
          icon: require('@/assets/img/icons/blue/new_family.png')
        },
        {
          title: this.$ml.get('content_coverages_house_step_4'),
          description: this.$ml.get('content_coverages_house_step_4_description'),
          icon: require('@/assets/img/icons/blue/new_plus.png')
        }
      ]
    }
  }
}
</script>
